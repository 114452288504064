
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[pageLocale]/[[...slug]]",
      function () {
        return require("private-next-pages/[pageLocale]/[[...slug]].js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[pageLocale]/[[...slug]]"])
      });
    }
  